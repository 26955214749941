.custom-pager-container {
  flex: 1;
  max-width: 90% !important;
}
.tableInnerWrapper .search-wraper .search__input {
  width: 100%;
  padding: 15px 0px 15px 50px;
  border: 1px solid #eceaf3;
  border-radius: 10px !important;
  outline: none;
  color: #7a86a1;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.tableInnerWrapper .search-wraper .search__input::placeholder {
  color: #7a86a1;
}
.tableInnerWrapper .search-wraper {
  position: relative;
}
.tableInnerWrapper .search-wraper .search-icon {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  cursor: pointer;
}
.tableInnerWrapper .pagination-wraper {
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  margin-top: 30px;
  column-gap: 13px;
  align-items: center;
}
.tableInnerWrapper .pagination-wraper button {
  cursor: pointer;
  width: 36px;
  height: 36px;
  border-radius: 15px;
  background-color: #fff;
  color: #c0bdcc;
  border: 1px solid #f0f0f0;
  font-size: 14px;
}
.tableInnerWrapper .pagination-wraper button.prev {
  border: unset;
  background: transparent;
}
.tableInnerWrapper .pagination-wraper button.active {
  background-color: #6149cd;
  color: #fff;
}
button.prev img {
  width: 20px;
}
button.next.prev img {
  transform: rotate(180deg);
}
.tableInnerWrapper table td.itemtitle {
  color: #6149cd !important;
  width: 50px;
}
.action-btns {
  display: flex;
  column-gap: 10px;
}
.action-btns button {
  font-size: 14px;
  padding: 0;
  background-color: unset;
  cursor: pointer;
}
.action-btns button:hover {
  opacity: 0.6;
  transition: all 0.2s ease-in-out;
}
.action-btns button .btn-img {
  width: 20px;
  height: auto;
  fill: rgba(122, 134, 161, 0.5);
}
.css-i4bv87-MuiSvgIcon-root {
  // width: 16px !important;
  // height: 16px !important;
  // fill: #7a86a1;
  // margin-right: 5px;
}
.desc-info {
  position: relative;
  display: inline-block;
}
.td-description {
  // height: 45px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
  width: 115px;
}

.desc-info .desc-tooltiptext {
  visibility: hidden;
  width: 350px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 4px 10px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: -15%;
  margin-left: auto;
  font-size: 13px;
}

.desc-info:hover .desc-tooltiptext {
  visibility: visible;
}

.custom-pager-container {
  flex: 1;
  display: flex;
  max-width: 90% !important;
}
.pagination {
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  margin-top: 30px;
  column-gap: 13px;
  align-items: center;
  list-style: none;
}
.pagination a {
  cursor: pointer;
  width: 36px;
  height: 36px;
  border-radius: 15px;
  background-color: #fff;
  color: #c0bdcc;
  border: 1px solid #f0f0f0;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination .active a {
  background-color: #6149cd;
  color: white;
  border-radius: 50px;
  border: 1px solid #f0f0f0;
}
li.previous.disabled a {
  border: none;
}
li.next a img {
  transform: rotate(180deg);
}

.css-1f2kw6w-MuiContainer-root {
  margin-left: 0 !important;
}
.prevPdf,
.nextPdf {
  position: fixed !important;
  border-radius: 50% !important;
  font-size: 18px !important;
  color: #a89bb9 !important;
  bottom: 50%;
  border: 1px solid #a89bb9 !important;
  background-color: #fff !important;
}
.nextPdf {
  right: 50px !important;
}

.pdfDocument {
  height: 100%;
  overflow-y: scroll;
}
.td-name {
  display: none;
}
.tittleSpan:hover {
  text-decoration: underline;
}

@media (max-width: 991px) {
  .td-name {
    display: block;
  }
  .td-name {
    font-size: 12px;
    color: #b7afaf;
  }
  .table-info table thead {
    display: none;
  }
  .table-info table tbody td {
    display: block;
    width: 100% !important;
    padding: 5px 10px;
    line-height: 20px;
  }
  .abf {
    justify-content: flex-start;
  }
  .nextPdf {
    right: 7px !important;
  }
}
