.pdf-preview-modal {
}
.pdf-preview-modal .controllersDetailsContainer {
  height: 890px;
  width: 270px !important;
  overflow-y: scroll;
  padding-left: 10px;
  padding-right: 10px;
  border-left: solid 1px #c1c1c1;
  margin-left: 10px;
}

/* Track */
.pdf-preview-modal .controllersDetailsContainer::-webkit-scrollbar {
  width: 3px;
}
.pdf-preview-modal .controllersDetailsContainer::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
.pdf-preview-modal .controllersDetailsContainer::-webkit-scrollbar-thumb {
  background: #6149cd;
}
/* Handle on hover */
.pdf-preview-modal .controllersDetailsContainer::-webkit-scrollbar-thumb:hover {
  background: #2e1e78;
}

.pdf-preview-modal .controllersDetailsContainer .textInputContainer {
}
.pdf-preview-modal .textInputContainer .nonActiveContainer {
  background-color: #f9fbff;
  border-radius: 10px;
  padding: 10px;
}
.pdf-preview-modal .textInputContainer .control-options {
  font-size: 13px;
  margin-bottom: 15px;
  margin-top: 5px;
}
.pdf-preview-modal .textInputContainer .control-options .info {
  display: flex;
  justify-content: space-between;
}
.pdf-preview-modal .textInputContainer .control-options .info .page-num {
}
.pdf-preview-modal .textInputContainer .control-second-section {
}
.pdf-preview-modal
  .textInputContainer
  .control-second-section
  .cs-section-item {
  display: flex;
  column-gap: 5px;
}
.pdf-preview-modal
  .textInputContainer
  .control-second-section
  .cs-section-item
  .title {
  font-weight: 600;
}
.pdf-preview-modal
  .textInputContainer
  .control-second-section
  .cs-section-item
  .desc {
}
