.st-innerwraper {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}
.sp-close {
  position: absolute;
  right: 20px;
  cursor: pointer;
}
p.templates {
  color: #4a4a4a;
  padding-bottom: 12px;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
}
.st-leftside {
  width: 48%;
  margin-right: 15px;
}
.temp-list {
  background-color: #fff;
  font-size: 15px;
}
.templist-des {
  color: #b5bbc8;
  font-size: 11px;
  font-weight: 400;
}
.css-17n35xh {
  background-color: #eceef2 !important;
}
// .temp-list {
//     background-color: #fff !important;
//     margin-bottom: 12px;
//     padding: 12px;
//     border-radius: 15px;
//     border: 1px solid transparent;
//     cursor: pointer;
// }
.temp-list {
  margin-bottom: 12px;
  padding: 12px;
  border-radius: 15px;
  border: 1px solid transparent;
  cursor: pointer;
  border: solid 1px #6149cd !important;
  background-color: #f9fbff;
}
.onApplytemplate {
  width: 90px;
  float: right;
  margin-top: 12px !important;
}
