.m-control-third-section {
  max-width: 100% !important;
  justify-content: space-between;
  align-items: center;
}
.p-btn {
  font-size: 14px !important;
  color: #191a1d !important;
  background-color: #e9e9e9 !important;
  padding: 7px 15px !important;
  width: 100%;
  max-width: max-content;
  text-align: center;
  border-radius: 32px !important;
  cursor: pointer;
}
.p-btn:hover {
  color: #6149cd !important;
}
.m-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 12px;
  width: 100%;
}
.m-btn-wraper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  align-items: center;
}
.cs-btn {
  display: flex;
  column-gap: 12px;
  align-items: center;
}
.next-btn {
  width: 100%;
  max-width: max-content;
  float: right;
  color: #6149cd !important;
  text-decoration: underline !important;
  background-color: unset;
}
.next-submit-btn {
  color: #fff;
}
.next-btn:hover {
  // font-weight: 700;
  opacity: 0.8;
}
.ec-parent {
  padding-bottom: 100px;
}
.editpagecon {
  background-color: rgb(236, 238, 242);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom-right-radius: 11px;
  border-bottom-left-radius: 11px;
}
.editcntrl {
  display: flex;
  position: fixed;
  right: 0px;
  bottom: 0px;
  column-gap: 8px;
  z-index: 999;
  width: 100%;
  justify-content: flex-end;
  background: #fff;
  padding: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}
.css-em2flm-MuiTypography-root {
  text-align: center;
}
.miisign-logo {
  padding-top: 30px;
}
.miisign-logo img {
  width: 77px;
}
////////////
textarea::placeholder {
  font-size: 9px;
  text-align: center;
  color: black;
}
textarea {
  border-color: transparent;
  outline: none;
}
// .css-xpbrf4 {
.pdfscroll {
  // background-color: red !important;
  @media screen and (max-width: 480px) {
    // background-color: green !important;

    // width: 100%;
    overflow: scroll !important;
  }
}

.mb-pdfInput {
  border-radius: 0;
  font-size: 8px;
}

// m-tabs
.m-tabs {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 5px 0;
  .l-border {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .r-border {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .tab {
    border: solid 1px #c6c6c6;
    padding: 7px 35px;
    font-size: 12px;
    text-transform: capitalize;
    cursor: pointer;
  }
  .tab:hover {
    color: #fff;
    background: #6149cd;
    transition: 0.2s ease-in-out;
  }
  .active {
    color: #fff;
    background: #6149cd;
  }
}

// signature by text
.by-text-container {
  width: 100%;
  .input-area {
    display: flex;
    justify-content: center;
    gap: 10px;
    position: relative;
  }
  button {
    background: #6149cd;
    color: #fff;
    border-radius: 25px;
    padding: 5px 25px;
    font-size: 14px;
  }
  .image-container {
    min-width: 300px;
    width: 100%;
    height: 200px;
    border-radius: 20px;
    border: 2px solid #e9e9e9;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    img {
      width: 100%;
    }
  }
}
.sign-with-image {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 2px;
  position: relative;
  height: 150px;
  // width: 150px;
  align-items: center;
  .sign-img {
    // width: 100%;
  }
}
