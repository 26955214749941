@import "Colors.scss";
$backgroundImage: url("../Assets/SingUp/background.webp");
.logo {
  height: 30px;
  width: 60px;
  // margin-top: 50px;
  // margin-left: 64px;
}
.leftCircle {
  // height: 480px;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  // margin-left: 94px;
  // margin-top: 100px;
}
.leftCircle img {
  width: 100%;
}
.background-Image {
  background: $backgroundImage;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
}
.rightSection {
  background-color: #{$backgroundColor};
  height: 100vh;
  position: relative;
}
img.rightCircle {
  width: 400px;
  position: absolute;
  bottom: 0;
  left: -190px;
}
.form {
  background: linear-gradient(45deg, #ebe7fa, #ebe7fa, #fff, #ffffff);
}
.copyright {
  color: #{$primaryTextColor};
  font-size: 14px;
  // margin-left: 30px;
  // margin-top: 10px;
}
.formDiv {
  // margin-left: 136px;
  padding: 0 100px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: 100vh;
  justify-content: center;
  // justifyContent: "space-between",
  //   alignItems: "center",
}
.formDiv .heading {
  margin-bottom: 20px;
}
.formDiv .sub-heading {
  margin-top: 10px;
  margin-bottom: 20px;
}
.signInButton {
  width: "30%";
  border-radius: "50px";
  padding: "10px";
  margin-top: 10;
}
.errorMessage {
  color: #d32f2f;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 4px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}
.createUserForm {
  @extend .form;
  height: 100vh;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.css-k4qjio-MuiFormHelperText-root {
  font-size: 10px !important;
}
.alert {
  position: relative;
  margin-top: 15px;
  padding: 0.75rem 1.25rem;
  border: 1px solid #6149cd;
  border-radius: 5px;
  z-index: 1;
}
.alert-primary {
  color: #004085;
  background-color: #cce5ff;
}
.innerForm {
  width: 300px;
}
.heading.heyhello {
  margin-bottom: 5px;
  font-size: 24px;
}
.rightsectmob .formDiv .sub-heading {
  margin-top: 5px;
  margin-bottom: 10px;
}
.c-input.reg-input {
  margin-bottom: 10px;
}
.btnflex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 1;
}
.login__btn {
  background-color: transparent !important;
  border: 1px solid #6149cd !important;
  color: #6149cd !important;
  padding: 5px 0px !important;
  width: 100%;
  max-width: 48%;
  cursor: pointer;
  border-radius: 50px !important;
}
.login__btn:hover {
  opacity: 0.7;
}
.register__btn {
  background-color: #6149cd !important;
  color: #ffff !important;
  padding: 5px 0px !important;
  width: 100%;
  border: 1px solid #6149cd !important;
  max-width: 48%;
  border-radius: 50px !important;
}
.register__btn:hover {
  opacity: 0.7;
}

.formDiv.regformdiv,
.background-Image.rightsectmob {
  height: calc(100vh + 60px);
}
.right__circle {
  position: absolute;
  width: 400px;
  bottom: 0;
  right: 35%;
  z-index: 0;
}
.innerForm input {
  position: relative;
  z-index: 99;
}

@media (max-width: 991px) {
  .rightSection {
    height: fit-content;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 35px;
    width: 100%;
    max-width: 500px !important;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 20px;
    backdrop-filter: blur(5px);
  }
  .formDiv.regformdiv {
    height: unset !important;
  }

  .leftCircle {
    top: 33%;
  }
  .formDiv {
    height: 100%;
    display: block;
    padding: 0;
  }
  .form,
  .createUserForm {
    background: unset;
  }
  .innerForm {
    width: 100%;
  }
  .yellowBg {
    height: 100vh;
  }
  img.rightCircle {
    display: none;
  }
}
@media (max-width: 767px) {
  .rightSection {
    max-width: 90% !important;
    padding: 25px 20px;
  }
  .formDiv.regformdiv {
    height: 100vh;
  }

  .copyright {
    width: 156px;
  }
  .formDiv .heading {
    margin-bottom: 0px;
    font-size: 28px;
  }
  .rightsectmob {
    // margin: 70px 0px !important;
    min-height: calc(100vh + 150px);
  }
  .rightSectionmrgnTop {
    margin: 71px 0px 0px !important;
  }
}
