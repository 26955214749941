@font-face {
  font-family: "Manrope";
  src: local("Manrope"), url(../Fonts/Manrope-Regular.ttf) format("ttf");
}

body,
.css-10hburv-MuiTypography-root {
  font-family: "Manrope", sans-serif !important;
}

@mixin commonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.css-k5qksa {
  margin: 0 !important;
  padding: 0 !important;
}
.textfield {
  width: 250px;
}

.css-1f8bwsm {
  min-width: auto !important;
  margin-right: 10px !important;
}
// custom input field
.c-input {
  margin-bottom: 15px;
}
// button
button {
  text-transform: capitalize !important;
}
// right section
.right-section {
  position: relative;
}
.right-section .sb-image {
  padding: 0 !important;
  margin: 0 !important;
}
// right menu items
.MuiList-root {
  width: 100%;
  padding: 10px !important;
  margin-top: 30px !important;
}
.right-menu {
  margin-top: 0 !important;
}
.right-menu .MuiButtonBase-root {
  padding: 5px 15px !important;
  margin-bottom: 5px !important;
}
.right-menu .css-cveggr-MuiListItemIcon-root {
  min-width: auto !important;
  margin-right: 10px !important;
}
.right-menu span {
  font-weight: 300 !important;
  font-size: 14px;
}

.right-section .btm-img {
  position: absolute;
  bottom: 0px;
  left: 0;
}
.right-section .btm-img img {
  width: 100%;
}
.right-section .btm-img .cp-right {
  position: absolute;
  bottom: 20px;
  left: 25px;
  color: #6149cd;
  font-size: 14px;
}
// default cart
.d-cart {
  box-shadow: unset !important;
  border: 1px solid #dddddd;
  border-radius: 10px;
  padding: 20px 20px 45px 20px;
  line-height: 35px;
  background: linear-gradient(218deg, #e3e3f1, transparent);
  margin-bottom: 30px;
}
.d-cart .title {
  font-size: 14px;
  font-weight: 500;
  color: #6149cd;
}
.d-cart .number {
  font-size: 45px;
  font-weight: 500;
}

// table
.tableInnerWrapper {
  border-radius: 10px;
  padding-bottom: 28px;
  // padding-top: 10px;
  background-color: #fdfdfd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.tableInnerWrapper table {
  border-collapse: collapse;
  width: 100%;
  border-radius: 10px;
}
// .tableInnerWrapper table thead th{
//   background: #F6F4FF !important;
// }
.tableInnerWrapper table th {
  padding: 17px 19px;
  text-align: left;
}
.tableInnerWrapper table td,
.tableInnerWrapper table th {
  padding: 17px 19px;
  text-align: left;
}

.tableInnerWrapper table th {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #000;
}

.tableInnerWrapper table td {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #464255;
}

.tableInnerWrapper table td img {
  margin-right: 12px;
  border-radius: 100%;
}

label {
  margin-bottom: 0px;
}

.tableInnerWrapper table tr {
  border-bottom: 1px solid #eceaf3;
  transition: transform 0.2s ease-in-out;
  border-left: 5px solid transparent;
  cursor: pointer;
  background-color: #ffff;
  position: relative;
}

.tableInnerWrapper table tr .t-center {
  text-align: center;
}
.tableInnerWrapper table tr .actions {
  text-align: center;
  display: flex;
  justify-content: center;
}
.tableInnerWrapper table tr .actions a {
}
.tableInnerWrapper table tr .icon {
  min-width: auto;
  margin: 0 2px;
}
.tableInnerWrapper table tr .icon:hover {
  opacity: 0.5;
  transition: all 0.2s;
}
.tableInnerWrapper table tbody tr:hover {
  border-left: 5px solid #6149cd;
  background-color: #fff;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.08);
  z-index: 99;
}

.tableInnerWrapper table tbody tr:nth-child(odd) {
  background-color: #fcfcfc;
}
.tableInnerWrapper table tbody tr:nth-child(odd):hover {
  background-color: #fff;
}
input,
select {
  padding: 8.5px 14px;
  border-radius: 25px;
  border: solid 1px rgba(122, 134, 161, 0.14);
}
input::placeholder {
  color: #7a86a1;
}
.cs-section-item input {
  width: 100%;
  padding: 12px;
}
// chart
.chart {
  width: 100%;
  // max-width: 100%;
  position: relative;
}

// dashboard pie chart

.d-pie-chart {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
.modalwraper {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;
  opacity: 0.8;
  top: 0px;
  left: 0;
  border-radius: 10px;
}
.menuBar {
  display: none !important;
}
.d-desk {
  display: block;
}
.d-mobile {
  display: none;
}
.flex {
  display: flex;
  align-items: center;
}
.piechartcontainer {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  margin-top: 70px;
  border-radius: 10px;
  margin-left: 10px;
}
.PieChartData {
  padding-bottom: 20px;
  padding-left: 20px;
}
.PieChartData ul li {
  line-height: 1.42857;
  vertical-align: top;
  color: #304e75 !important;
  font-size: 13px;
  display: flex;
  margin-bottom: 5px;
}
.PieChartData ul li .pending {
  background-color: rgb(97, 73, 205);
  padding: 2px 13px;
  margin-right: 5px;
}
.PieChartData ul li .signed {
  background-color: rgb(248, 191, 52);
  padding: 2px 13px;
  margin-right: 5px;
}
.PieChartData ul {
  display: flex;
  column-gap: 15px;
  justify-content: CENTER;
}
.PieChartData ul li .conpleted {
  background-color: rgb(116, 206, 115);
  padding: 2px 13px;
  margin-right: 5px;
}
.PieChartData ul li .paid {
  background-color: #00ada3;
  padding: 2px 13px;
  margin-right: 5px;
}
.row {
  display: flex;
  flex-direction: row !important;
}
.close-icon {
  display: none;
}
// profile styling starts
.rolename {
  font-weight: 700;
}
.roleWraper {
  margin: 10px 0px 0px;
  padding: 20px 20px;
  border-top: 1px solid #e9dcdc;
  border-bottom: 1px solid #e9dcdc;
}
.p-username {
  display: flex;
  width: 100%;
  margin-bottom: 25px;
}
.roleadmin {
  font-size: 14px;
  font-weight: 500;
}
.ist-column {
  font-size: 15px;
  font-weight: 600;
}
.passlabe {
  font-size: 11px;
}
.pass-col {
  width: 50%;
}
.ist-column,
.third-column {
  width: 100%;
  max-width: 20%;
}
.third-column {
  text-align: right;
  font-size: 15px;
  cursor: pointer;
  align-items: flex-start;
}
.secnd-column {
  width: 100%;
  max-width: 60%;
}
.cansubbtn {
  column-gap: 12px;
  justify-content: flex-end;
}
.cansubbtn div {
  cursor: pointer;
}
.p-username input {
  // width: 100%;
  // max-width: 260px !important;
}
.editsaveBtn {
  color: #6149cd;
  text-decoration: underline;
  font-weight: 600;
}
.editsaveBtn:hover {
  opacity: 0.8;
}
.cancelbtn {
  color: rgb(255, 48, 48);
  text-decoration: underline;
  font-weight: 600;
}
.cancelbtn:hover {
  opacity: 0.8;
}
// profile styling end

.trackingInfo.new-user {
  padding-bottom: 2px;
  margin-bottom: 15px;
}
.input-field-wraper {
  margin-bottom: 15px;
}
.trackingwraper.new-user-tw {
  height: 235px;
}
.template-form-wraper {
  width: 100%;
  padding: 30px;
}
.m-template {
  display: flex;
  column-gap: 50px;
}
.template-input-field {
  width: 80%;
}
.template-input-field input {
  padding: 10px 20px 15px;
}

.template-input-field label {
  font-size: 14px !important;
}
.template-submit {
  padding: 12px 40px !important;
  width: 100% !important;
  max-width: 140px !important;
  color: #fff !important;
  background-color: #6149cd !important;
  max-height: 48px;
  transition: 0.3s linear;
}
.template-submit:hover {
  opacity: 0.7;
}
.templateheading {
  font-size: 17px;
  font-weight: 700;
}
.templateheading span {
  font-weight: 400;
  color: #464255;
  font-size: 14px;
}
.canel-submit-wraper {
  display: flex;
  column-gap: 10px;
}
.canel-submit-wraper button {
  min-width: 140px;
  max-height: 48px;
}
.applytemplate {
  max-width: 97% !important;
  margin-left: auto !important;
  margin-top: 74px !important;
}
@media (max-width: 991px) {
  .applytemplate {
    max-width: 94% !important;
  }
  .menuBar {
    display: block !important;
    margin-right: 0px !important;
  }
  .d-desk {
    display: none;
  }
  .d-mobile {
    display: block;
  }
  .pdRight {
    padding-right: 0px !important;
  }
  .d-head {
    font-size: 18px !important;
    margin-top: 13px;
  }
  .createUser {
    padding: 5px 20px !important;
  }

  .user-form {
    padding: 20px;
  }
  .tableInnerWrapper {
    overflow: scroll;
  }
  .row-rev {
    display: flex;
    flex-direction: column-reverse;
  }
  .piechartcontainer {
    margin-top: 10px;
  }
  .row {
    flex-direction: column-reverse !important;
  }
  .piechartcontainer {
    margin-left: 0px;
  }
  .tableInnerWrapper.table-info table tr .actions {
    justify-content: flex-start;
  }
  .tableInnerWrapper.table-info table tr input {
    width: 100%;
  }
  .inputSelect {
  }
  // profile mobile styling
  .p-username {
    display: block;
  }
  .ist-column,
  .third-column,
  .secnd-column {
    max-width: 100%;
  }
  .third-column {
    text-align: left;
    justify-content: flex-start;
  }
  .secnd-column {
    width: 100%;
    margin-bottom: 5px;
  }
  .textfield,
  .secnd-column input {
    width: 100%;
  }
  .secnd-column.flex {
    display: block;
  }
  .pass-col {
    width: 100%;
  }
  .roleWraper {
    padding: 20px 10px;
  }
}

@media (max-width: 767px) {
  .right-section {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 999;
  }
  .right-section .sb-image {
    width: 100%;
  }
  .close-icon {
    border-radius: 5px;
    position: absolute;
    top: 15px;
    right: 24px;
    font-size: 20px;
    font-weight: 600;
    background: rgb(97, 73, 205);
    color: #fff;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 1px;
  }
  .nopadding {
    padding-right: 0px !important;
  }
  .rolename {
    padding-left: 10px;
  }
}
