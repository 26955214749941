@import "Colors.scss";
.tox .tox-statusbar {
  align-items: center;
  background-color: #fff;
  border-top: 1px solid #e3e3e3;
  color: rgba(34, 47, 62, 0.7);
  display: flex;
  flex: 0 0 auto;
  font-size: 14px;
  font-weight: 400;
  height: 25px;
  overflow: hidden;
  padding: 0 8px;
  position: relative;
  text-transform: none;
  display: none !important;
}
.displayRoleContainer {
  // background-color: red !important;
  // display: flex;
  // width: 100% !important;
  // justify-content: space-evenly;
}
.notactiveRadioButton {
  display: flex;
  align-items: center;
}
.activeRadioButtonContainer {
  display: flex;
  align-items: center;
  color: #{$primaryManagementHeadingColor};
}
.chooseFile {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.pdf-tabs-list {
  border-radius: 20px;
  padding: 10px;
  border: 2px solid #f0f2f6;
  display: flex;
  justify-content: space-around;
  width: 40%;
  margin: auto;
  margin-bottom: 30px;
}
.pdf-tabs-list .pdf-tabs {
}
.uploadpdf-wraper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.uploadpdf {
  width: 100%;
  max-width: 48%;
  padding: 15px;
  border-bottom: 1px solid #7a86a124;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.upload-inner p {
  color: #4a4a4a;
  font-weight: 400;
  font-size: 16px;
}
.upload-inner span {
  color: #7a86a1;
  font-weight: 400;
  font-size: 13px;
}
.inpdf {
  display: flex;
  column-gap: 12px;
  align-items: center;
}
.uploadbtn button {
  padding: 10px 19px 10px 19px;
  border: 1px solid #7a86a182;
  border-radius: 15px;
  font-size: 14px;
  font-weight: 400;
  background-color: #fff;
  color: #7a86a1;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 7px;
  cursor: pointer;
}
#radioButton {
  border: 2px solid red;
}
.upload-pdf-styles {
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.1);
  border-radius: 22px;
  // padding: 30px;
}
.recipient-form-wraper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  padding: 0px 30px;
}
.recipient-form-wraper .rec-input-field {
  width: 48%;
}
.recp-btn-wraper {
  display: flex;
  justify-content: flex-end;
  column-gap: 20px;
  align-items: center;
  margin-bottom: 30px;
  padding: 0px 30px;
}
.receipentListBox {
  width: 100% !important;
  overflow-y: unset !important;
  border-top: 1px solid rgba(122, 134, 161, 0.1411764706);
}
.receipentListRow {
  box-shadow: none !important;
  border-bottom: 1px solid rgba(122, 134, 161, 0.1411764706);
  padding: 10px 40px;
}
.edit_receipentListRow {
  box-shadow: none !important;
  border-bottom: 1px solid rgba(122, 134, 161, 0.1411764706);
  padding: 10px 0px;
}
.edit__recipient {
  box-shadow: none !important;
  border-bottom: 1px solid rgba(122, 134, 161, 0.1411764706);
  padding: 10px 0px;
}
.iconList {
  text-align: right !important;
}
.nextBtn {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  padding: 0px 40px 30px;

}
.nextBtn button {
  width: 20%;
}
