.documentContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  height: 500px !important;
  overflow-y: scroll !important;
  position: relative;
}
.toolBoxMainContainer {
  background-color: #eceef2;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.toolBoxSubContainer {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 10px;
  @media (max-width: 1024px) {
    width: 100%;
  }
}
.textBoxContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  // width: 80px;
  @media (max-width: 1024px) {
    // width: 90px;
    display: flex;
    justify-content: space-evenly;
  }
}
.mulitSelectionConatiner {
  @extend .textBoxContainer;
  // width: 110px;
  flex-direction: column;
  @media (max-width: 1024px) {
    // width: 120px;
    display: flex;
    justify-content: space-evenly;
  }
}
.imageBoxContainer {
  @extend .textBoxContainer;
  // width: 100px;
  flex-direction: column;

  @media (max-width: 1024px) {
    // width: 110px;
    display: flex;
    justify-content: space-evenly;
  }
}
.signatureBoxContainer {
  @extend .textBoxContainer;
  // width: 100px;
  flex-direction: column;

  @media (max-width: 1024px) {
    // width: 110px;
    display: flex;
    justify-content: space-evenly;
  }
}
.leftContainer {
  display: flex;
}

.gradient-border {
  border-width: 2px;
  border-image: linear-gradient(to right, #ff0000, #00ff00) 1;
}

.css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 5px !important;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
button {
  text-transform: capitalize !important;
  border: none;
}
.textBoxDrag {
  cursor: url("../Assets/Text box-50x50.png"), auto;
}
.multiSelectionDrag {
  cursor: url("../Assets/Multi selection-20x20.png"), auto;
}
.imageBoxDrag {
  cursor: url("../Assets/Image box-50x50.png"), auto;
}
.signatureBoxDrag {
  cursor: url("../Assets/Signature Box-50x50.png"), auto;
}
.initialBoxDrag {
  cursor: url("../Assets//Initials\ -50x50.png"), auto;
}
.radioButtonDrag {
  cursor: url("../Assets/Radio\ Button\ -20x20.png"), auto;
}
.signatureImageDrag {
  cursor: url("../Assets/Signature\ Box-50x50.png"), auto;
}
// pdf right controls
.pdf-right-controls {
  border-left: solid 2px #f5f6f6;
  padding: 10px;
  position: relative;
  height: 95%;
}
.pdf-right-controls .radio-btn {
  width: 15px;
}
.pdf-right-controls .main-title {
  font-size: 20px;
  color: #4a4a4a;
  margin-bottom: 5px;
}
.pdf-right-controls .textInputContainer {
  width: 100%;
}
.pdf-right-controls .nonActiveContainer {
  background-color: #f9fbff;
  padding: 5px 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border: solid 1px #f9fbff;
}

.pdf-right-controls .emailErrorContainer {
  background-color: #f9fbff;
  padding: 5px 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border: solid 1px red;
}
.pdf-right-controls .activeContainer {
  // border: solid 4px #6149cd;
}
.pdf-right-controls .controllersDetailsContainer {
  min-height: 270px;
  height: 270px;
  padding-top: 7px;
  padding-right: 7px;
  padding-left: 7px;
  overflow-y: scroll;
  border-radius: 10px;
}
/* Track */
.pdf-right-controls .controllersDetailsContainer::-webkit-scrollbar {
  width: 3px;
}
.pdf-right-controls .controllersDetailsContainer::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
.pdf-right-controls .controllersDetailsContainer::-webkit-scrollbar-thumb {
  background: #6149cd;
}
/* Handle on hover */
.pdf-right-controls
  .controllersDetailsContainer::-webkit-scrollbar-thumb:hover {
  background: #2e1e78;
}
.pdf-right-controls .control-options {
  margin-bottom: 30px;
  padding: 0 10px;
}
.pdf-right-controls .control-options .mandatory-btn {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  width: 71%;
  margin-top: 4px;
}
.pdf-right-controls .control-options .mandatory-btn button {
  margin-right: 5px;
}
.pdf-right-controls .control-options .control-second-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;
  margin: 10px 0;
  flex-direction: column;
}
.pdf-right-controls .control-options .control-second-section .cs-section-item {
  width: 100%;
}
.pdf-right-controls
  .control-options
  .control-second-section
  .cs-section-item
  .title {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}
.pdf-right-controls
  .control-options
  .control-second-section
  .cs-section-item
  select {
  width: 100%;
  border-radius: 10px;
  padding: 12px;
  border: solid 1px #f1f2f5;
}
.pdf-right-controls .control-options .control-third-section {
  display: flex;
  width: 100%;
  max-width: 400px;
  column-gap: 35px;
  row-gap: 8px;
  flex-direction: column;
}
.mainControlParent {
  border-top: 1px solid #d5e2ff;
  padding: 15px 0px;
  border-bottom: 1px solid #d5e2ff;
  margin-top: 24px;
}
.pdf-right-controls .control-options .control-third-section .info {
  font-weight: 500;
  font-size: 13px;
}
.pdf-right-controls .control-options .control-third-section .info span {
  color: #7a86a1;
  margin-left: 5px;
}
// document details section
.pdf-right-controls .document-detail-section {
  border-top: solid 2px #f5f6f6;
  margin-top: 5px;
  padding: 10px 0;
  border-radius: 8px;
  // position: absolute;
  // bottom: 0;
  // width: 95%;
  background-color: #fff;
}
.pdf-right-controls .document-detail-section input,
.pdf-right-controls .document-detail-section textarea {
  width: 95%;
  padding: 10px;
  border-radius: 10px;
  border: solid 1px #f1f2f5;
}
.pdf-right-controls .document-detail-section .dd-title {
  color: rgba(0, 0, 0, 0.4);
  font-size: 13px;
  margin-bottom: 5px;
  background: #f9fbff;
  padding: 8px 0px 8px 12px;
}
.pdf-right-controls .document-detail-section .dd-item {
  position: relative;
  margin: 9px 0px;
}
.pdf-right-controls .document-detail-section .dd-item .error-msg {
  color: #ff0000;
  font-size: 11px;
  margin-top: 3px;
}
.pdf-right-controls .document-detail-section .dd-item .title {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}
.pdf-right-controls .document-detail-section .dd-item .title span {
  color: #ff0000;
}
.pdf-right-controls .document-detail-section .dd-first-sec .dd-item {
  width: 100%;
}
.pdf-right-controls .document-detail-section .dd-first-sec {
  display: flex;
  justify-content: space-between;
  column-gap: 15px;
  padding: 0 12px;
  flex-direction: column;
}
.pdf-right-controls .document-detail-section .dd-second-sec {
  display: flex;
  column-gap: 30px;
  margin: 5px 0;
  padding: 0 12px;
  // justify-content: center;
}
.pdf-right-controls .document-detail-section .dd-third-sec {
  padding: 0 12px;
  // justify-content: center;
}
.pdf-right-controls .document-detail-section .dd-second-sec .notify-btn {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
}
.pdf-right-controls .document-detail-section .dd-second-sec .notify-btn button {
  margin-right: 5px;
}
.pdf-right-controls .document-detail-section .dd-third-sec {
}
.pdf-right-controls .document-detail-section .dd-third-sec textarea {
  height: 60px !important;
  width: 97% !important;
  font-family: "Manrope", sans-serif !important;
}
.bottom-buttons {
  width: 100%;
  border-top: solid 2px #f5f6f6;
  text-align: right;
  padding: 20px 0;
  margin-right: 10px;
}
.bottom-buttons button {
  background-color: #6149cd;
}
.bottom-buttons button.next-btn {
  margin-left: 10px;
}
.react-resizable-handle-se {
  bottom: -5px !important;
  right: -5px !important;
}
.error-msg {
  color: #ff0000;
  font-size: 11px;
  margin-top: 3px;
}
canvas.sigCanvas {
  // box-shadow: 0 0 5px #6149cd;
  border-radius: 20px;
  border: 2px solid #e9e9e9;
  display: flex;
  align-items: center;
  align-self: center;
  margin-top: 10px;
}
.hir-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.hir-flex button {
  padding: 4px 16px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}
// m style changes
.select-filter {
  padding: 10px 0px 10px;
  border-bottom: 1px solid #f1f2f5;
}

.select-filter select {
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 96%;
  background-position-y: 3px;
  position: relative;
  outline: 0;
  width: 100%;
  padding: 8px 10px;
  color: #000;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #f1f2f5;
  border-radius: 7px;
  font-size: 12px;
}
.select-filter select:focus {
  outline: none;
}
.new-user-btn button {
  margin: 10px 0px 10px;
  width: 100%;
  font-size: 13px;
}
.quick-controls {
  background: #fff;
  padding: 0px 10px;
  margin-bottom: 15px;
  border-radius: 8px;
}
.receipent-select
  > .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 8px 14px !important;
  margin-top: 0px !important;
}
ul.MuiList-root {
  margin: 0px !important;
  padding: 0px !important;
}
.cancel_btn {
  position: absolute;
  border-radius: 50%;
  cursor: pointer;
  color: #fff;
  background-color: red;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -11px;
  right: -13px;
}
.cancel_btn svg {
  width: 13px;
  height: 13px;
}
.expiry__flow {
  width: 100%;
  padding: 12px;
}
.expiry__label span {
  font-size: 12px !important;
}
.expiry__select__field {
  width: 100%;
  margin-bottom: 15px;
}
.expiry__select__option div {
  font-size: 14px;
  padding: 7px 10px !important;
}
.expiry__date__select {
  margin-top: 20px !important;
  width: 100%;
}
.expiry__date__select input {
  font-size: 14px !important;
}
.zoombtnWraper {
  display: flex;
  column-gap: 7px;
}
.zomms-btns {
  font-size: 20px;
  background: #6149cd;
  color: #ffff;
  border-radius: 4px;
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.zomms-btns:hover {
  opacity: 0.6;
}
.filter__text {
  font-size: 14px !important;
}
.receipent-select {
  font-size: 12px !important;
}
.edit__user__btn {
  margin-top: 0px !important;
}
