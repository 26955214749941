.card {
  padding: 10px;
  box-shadow: 0 0 5px #ffdfdf;
  border-radius: 5px;
  overflow: hidden;
  width: 400px;
}
.card .top {
  text-align: center;
}
.card p {
  font-weight: bold;
  color: #6149cd;
}
.card button {
  outline: 0;
  border: 0;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 400;
  padding: 8px 13px;
  width: 100%;
  background: #6149cd;
}
.card .drag-area {
  height: 150px;
  border-radius: 5px;
  border: 2px dashed #6149cd;
  color: black;
  background: #f4f3f9;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  -webkit-user-select: none;
  margin-top: 10px;
}
.card .select {
  color: #6149cd;
  margin-left: 5px;
  cursor: pointer;
  transition: 0.4s;
}
.card .select:hover {
  opacity: 0.6;
}

.card .container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  max-height: 200px;
  overflow-y: auto;
  margin-top: 10px;
}
.card .container .image {
  width: 75px;
  margin-right: 5px;
  height: 75px;
  position: relative;
  margin-bottom: 8px;
}
.card .container .image img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.card .container .image span {
  position: absolute;
  top: -2px;
  right: 9px;
  font-size: 20px;
  cursor: pointer;
}
.card input,
.card .drag-area .on-drop,
.card .drag-area.dragover .visible {
  display: none;
}

.delete {
  z-index: 999;
  color: #6149cd;
}
