@import "Colors.scss";
@import "Common.scss";
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0px;
  padding: 0px;
}
.headingContainer {
  // margin-left: 20px;
  p {
    margin-top: 10px;
    color: #{$primaryManagementHeadingColor};
    font-size: 30px;
    letter-spacing: 1px;
    &.small {
      font-size: 14px;
      color: #{$primarySideMenuHeadingColor};
      letter-spacing: 0px;
    }
  }
}
.subHeadingLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 70px;
  margin-top: 20px;
}
.displayRoleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100% !important;
  align-self: center !important;
  align-items: center;
  // margin-left: 15px;
  padding: 0 10px;
}
.roleContainerList {
  border-top: 1px solid #{$borderColor};
  border-bottom: 1px solid #{$borderColor};
  padding: 0;
}
.roleContainerList button.roles-slide {
  border-radius: 10px;
  z-index: 1;
  margin-left: 5px;
}
.screenContainer {
  @include commonContainer;
  width: 97%;
}
.permissionsSelectionContainer {
  border-top: solid 1px #{$borderColor};
  @include commonContainer;
  margin-top: 30px;
  padding-top: 15px;
}
.textControl {
  display: flex;
  flex: 1;
  // flex-direction: column;
  background-color: red;
}
.controlsContainer {
  display: flex;
  width: 100%;
  // margin-left: 10px;
  background-color: #{$lightBlueColor};
  justify-content: space-between;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.aContainer {
  width: 55px !important;
}
.activeScreenContainer {
  width: 80%;
  // height: 100px;
  background-color: #{$selectedScreenBackgroundColor};
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: "center";
  border-radius: 10px;
  padding: 15px;
  margin-left: 15px;
}
.nonActiveScreenContainer {
  @extend .activeScreenContainer;
  background-color: transparent;
}
.nonActiveScreenContainer span {
  font-size: 12px;
  text-transform: capitalize;
  margin-top: 5px;
  color: #bcc3d0;
}
.nonActiveScreenContainer:hover {
  background-color: #f6f4ff;
  cursor: pointer;
  transition: all 0.2s;
}
.screen-slider {
  margin-top: 20px;
}
.slick-track {
  width: 0px;
}
.slick-track {
  // width: 10px;
  // background-color: red;
}
.slick-slide.slick-active.slick-current {
  // width: 0px !important;
}
.roleListContainer {
  width: 100%;
  max-width: "100%";
  overflow-x: scroll;
  white-space: nowrap;
  padding: 20px 0;
  // background-color: red;
  @media (max-width: 1024px) {
    width: 100%;
  }
}
.react-pdf__Document {
  .react-draggable {
    div {
      p {
        // padding: 10px;
        padding-bottom: 0px;
      }
    }
  }
}
.react-pdf__Document .react-draggable div p {
  position: initial;
}

.roleListContainer::-webkit-scrollbar {
  width: 2px;
  height: 4px;
}

.roleListContainer::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.roleListContainer::-webkit-scrollbar-thumb {
  background: #{$primaryButtonsColor};
}

.roleListContainer::-webkit-scrollbar-thumb:hover {
  background: #{$primaryButtonsColor};
}
.slick-dots {
  position: absolute;
  bottom: -14px !important;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.d-text {
  display: inline-block;
  max-width: 50%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  word-wrap: normal;
  font-size: 13px;
  color: #7a86a1;
  text-transform: capitalize;
}
.activeScreenContainer .d-text {
  color: #6149cd;
}
.slick-dots li.slick-active button:before {
  color: #{$primaryButtonsColor} !important;
}
.slick-prev:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #c0bdcc !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #c0bdcc !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-prev {
  left: 2px !important;
  z-index: 1;
  // top: 38px !important;
}
.slick-next {
  right: -5px !important;
  // top: 38px !important;
}
