// .css-15m68yy {
//   height: 471px;
//   overflow: auto;
// }
.trackingInfo {
  position: sticky;
  top: -17px;
  left: 0;
  width: 100%;
  text-align: center;
  background-color: #fff;
  padding: 12px 0px 1px;
  border-bottom: 1px solid #dfd5d5;
}
.okBtn {
  position: sticky !important;
  left: 0;
  width: 100% !important;
}
.trackingwraper {
  height: 320px;
  overflow: auto;
}
.trackingBox {
  padding: 12px 20px !important;
}
.edit_trackingBox {
    padding: 20px 30px !important;
}
.trackingwraper::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.trackingwraper::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.trackingwraper::-webkit-scrollbar-thumb {
  background: #6149cd;
  border-radius: 6px;
}

/* Handle on hover */
.trackingwraper::-webkit-scrollbar-thumb:hover {
  background: #6149cd;
}
.trackingwraper .td-name {
  display: block;
}
.trackingwraper .td-name {
  font-size: 12px;
  color: #b7afaf;
}
// popup table
.trackingwraper .table-info table {
  width: 100%;
}
.trackingwraper .table-info table tbody td {
  display: block;
  width: 100% !important;
  padding: 5px 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #464255;
  clear: both;
}
.trackingwraper .table-info table tbody td ul.ptable-ul {
}
.trackingwraper .table-info table tbody td ul.ptable-ul li {
  list-style-type: none;
  float: left;
  width: 50%;
  display: flex;
  gap: 5px;
  margin-bottom: 5px;
}
.trackingwraper .table-info table tbody td ul.ptable-ul li .checkbx {
}
.trackingwraper .table-info table tbody td ul.ptable-ul li .checkbx-title {
}
.userHead {
  font-size: 18px !important;
  background: #eef3ff;
  padding: 5px 0px;
  margin-top: 15px !important;
}
.even-tr {
  background-color: #fcfcfc;
  border-bottom: 1px solid #eceaf3;
  border-top: 1px solid #eceaf3;
}
.checkbx-title {
  font-size: 12px;
}
.trackingInfo span {
  font-size: 12px;
  color: #b7afaf;
  display: block;
  text-align: center;
  font-weight: 300;
}
